* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
html,
body {
  color: #181818;
  height: 100%;
  font-family: "Orbit", sans-serif;
}
button {
  font-family: "Orbit", sans-serif;
}

.App {
  color: #181818;
  font-size: 1rem;
}
section {
  padding: 5rem 4rem;
  height: 100%;
}
section:nth-child(2n) {
  background-color: #24272b;
  /* background-color: rgb(214, 214, 214); */
}
section:nth-child(2n + 1) {
  background-color: #34343a;
  /* background-color: rgb(221, 229, 235); */
}
li {
  list-style: none;
}
p {
  font-family: "Noto Sans KR", sans-serif;
  letter-spacing: 0.5px;
}
input {
  font-family: "Noto Sans KR", sans-serif;
  letter-spacing: 0.5px;
}
textarea {
  font-family: "Noto Sans KR", sans-serif;
  letter-spacing: 0.5px;
}
@media (max-width: 425px) {
  html,
  button,
  a {
    font-size: 0.6rem;
    transition: font-size 0.3s ease;
  }
}

@media (min-width: 426px) and (max-width: 768px) {
  html,
  button,
  a {
    font-size: 0.9rem;
    transition: font-size 0.3s ease;
  }
}

@media (min-width: 769px) and (max-width: 1024px) {
  html,
  button,
  a {
    font-size: 0.95rem;
    transition: font-size 0.3s ease;
  }
}

@media (min-width: 1025px) {
  html,
  button,
  a {
    font-size: 1rem;
  }
}
