.FixedMenuButton .fix_btn {
  position: fixed;
  bottom: 3rem;
  right: 3rem;
  z-index: 999;
  color: rgb(202, 202, 202);
  border: none;
  border-radius: 10rem;
  background-color: #263088;
  box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.3);
  height: 3.5rem;
  width: 3.5rem;
  font-size: 1.9rem;
  cursor: pointer;
}

.FixedMenuButton .btn_wrapper {
  position: fixed;
  bottom: 2.5rem;
  right: 1.9rem;
  z-index: 900;

  border: none;
  border-radius: 5rem;
  background-color: #ffffff;
  box-shadow: 0 0 0.9rem rgba(0, 0, 0, 0.3);
  padding-top: 1.5rem;
  width: 5.5rem;
  height: 0; /* 초기 높이를 0으로 설정 */
  opacity: 0; /* 초기 불투명도를 0으로 설정 */
  overflow: hidden; /* 내용이 넘치지 않도록 설정 */
  transition: height 0.7s ease, opacity 0.7s ease;
  text-align: center;
}

.FixedMenuButton .btn_wrapper.visible {
  height: 20rem; /* 최종 높이를 설정 */
  opacity: 1; /* 최종 불투명도를 설정 */
}

.FixedMenuButton .btn_wrapper .goto {
  border: none;
  color: rgb(41, 41, 41);
  font-size: 0.9rem;
  background: none;
  padding: 0.7rem 0;
  cursor: pointer;
}

.FixedMenuButton .btn_wrapper .goto:hover {
  font-weight: bold;
}
